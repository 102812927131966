import { endpointV2, authHeaders } from '../../api';
import { ADDRESS_FINDER_AUTOCOMPLETE_ROUTE, ADDRESS_FINDER_GEO_CODE, ADDRESS_FINDER_PLACE_DETAILS_ROUTE } from '../../api/routes';

async function autocomplete(query) {
    return endpointV2.get(ADDRESS_FINDER_AUTOCOMPLETE_ROUTE, {
        headers: authHeaders(),
        params: { query },
    });
}

async function getPlaceDetails(placeId) {
    return endpointV2.get(ADDRESS_FINDER_PLACE_DETAILS_ROUTE, {
        headers: authHeaders(),
        params: { placeId },
    });
}

async function getGeoCodeFromAddress(query) {
    return endpointV2.get(ADDRESS_FINDER_GEO_CODE, {
        headers: authHeaders(),
        params: { query },
    });
}

export default {
    autocomplete,
    getPlaceDetails,
    getGeoCodeFromAddress,
};
